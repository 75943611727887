.sideNavMenu {
  height: 100vh;
  background-color: #fff;
  width: 225px;

}

.sideNavMenu :global(.ant-menu) {
    background-color: #fff;
    color: #333;

}

.sideNavMenu :global(.ant-menu-inline), .sideNavMenu :global(.ant-menu-item) {
  /* border-right: 1px solid #ccc; */
}

.sideNavMenu :global(.ant-menu-item:hover) , .sideNavMenu :global(.ant-menu-submenu-title:hover) {
  /* background-color: rgba(38,141,221,.1); */
  /* background-color: #997733; */
  /* background-color: #eef3ff; */
  background-color: rgba(17,24,39,.075);
  /* border-right: 2px solid  #ff0854; /* #D0021B; */
  transition: border-color 1s, background .3s, padding .15s cubic-bezier(0.645,.045,.355,1);
  color: #111;
}
  /* background-color: #0756ea; */

.sideNavMenu :global(.ant-menu:not(.ant-menu-horizontal)) :global(.ant-menu-item-selected) {
  /* background-color: #eef3ff; */
  background-color: rgba(17,24,39,.075);
}

.sideNavMenu :global(.ant-menu-item-selected),
.sideNavMenu :global(.ant-menu-item-selected a),
.sideNavMenu :global(.ant-menu-item-selected a:hover),
.sideNavMenu :global(.ant-menu-item a:hover) {
  color: #333;
  /* color: #D0021B; */
  /* color: #0756ea; */
  /* border-right:2px solid #0756ea; */
}

.sideNavMenu :global(.ant-menu>.ant-menu-item-divider) {
  margin-right: 10px;
  margin-left: 10px;
}

.sideNavMenuBrandName {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 00px;

  font-weight: 500;
  font-size: 18px;
}