

.sideNavModule {
  height: 100vh;

  background-color:rgb(255, 255,255);
  width: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* border-right-width: 1px;
  border-color: rgb(229 231 235); */
  box-shadow: 0 1px 1px 1px #ddd;
  /* color: #3d87db */
  border-right: 1px solid #ddd;
}

.sideNavModuleMenuWrapper {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideNavModuleFooterWrapper {
  margin-bottom: 20px;
}

.sideNavModuleMenuIcon {
  padding: 10px 10px 3px 10px;

  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  /* border: 1px solid #ff0854; */
  /* box-shadow: 0 0 0 2px #ff0854; */
  /* box-shadow: 0 0 0 2px #6c5ed6; */
  box-shadow: 0 0 0 2px #333333aa;

  /* border: 1px solid black; */

  /* background-color: rgba(255, 255, 255, 0.954) */
  /* background-color: rgb(227, 226, 226); */
  /* background-color: #6c5ed6; */
  background-color:#333333aa;
  cursor: pointer;

  /* box-shadow: 1px 1px 5px #aaa; */
}

.sideNavModuleMenuIcon > svg {
  /* stroke: #ff0854; */
  stroke: #fff;
  /* background-color: #555555aa; */
  stroke-width: 2.0;
}

.sideNavModuleMenuIcon:hover {
  background-color: #fff;
  stroke: #333333aa;
  /* background-color: #ff0854; */
  /* background-color: #D0021B; */
  /* box-shadow: 1px 1px 30px #888; */
}

.sideNavModuleMenuIcon:hover > svg {
  stroke: #555555aa;
  background-color: #fff;
  /* stroke: #fff; */
  /* stroke-width: 2.0; */
}

.sideNavModuleIcon {
  padding: 8px 10px 3px 10px;

  margin-bottom: 10px;
  border-radius: 8px;
}

.sideNavModuleIcon:hover {
  background-color: rgba(17,24,39,.075);
  border:rgba(17,24,39,.075);

  cursor: pointer;
}

.sideNavModuleIcon:hover > svg {
  stroke: #333;
}

.sideNavModuleIconActive {
  padding: 8px 10px 3px 10px;

  margin-bottom: 10px;
  border-radius: 8px;

  background-color: rgba(17,24,39,0.25);
  border:rgba(17,24,39,0.25);
  cursor: pointer;
}

.sideNavModuleIconActive > svg {
  stroke: #333;
}

/* .sideNavModule span {
  color: #fff;
  cursor: pointer;


} */