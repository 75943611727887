.ant-form-item {
  margin-bottom: 10px;
}

.ant-form-item .ant-form-item-explain {
  font-size: 12px;
}

/********************************************************
 * input : Text
 ********************************************************/
 .ant-input:hover, .ant-input:focus {
  border-inline-end-width: unset;
  box-shadow: 0 0 0 1px #1589FF;
}

 .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  /* border-color: #6c5ed6; */
  border-color: #1589FF;
  border-inline-end-width: unset;
  z-index: 1;
  box-shadow: 0 0 0 1px #1589FF;
}

.ant-input-affix-wrapper > input.ant-input {
  box-shadow: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  box-shadow: none;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  box-shadow: none;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus-visible {
  outline: none;
}
.ant-input-affix-wrapper-lg {
  outline: none;
}

.ant-input-affix-wrapper:focus  {
  outline: none;
  border-color: #1589FF;
  box-shadow: 0 0 0 1px #1589FF;
}

.ant-input-affix-wrapper-focused  {
  outline: none;
  border-color: #1589FF;
  box-shadow: 0 0 0 1px #1589FF;
}


.ant-form-vertical .ant-form-item .ant-form-item-control:focus-visible {
  outline: none;
}

.ant-form-large .ant-form-item .ant-form-item-control-input:focus-visible {
  outline: none;
}

.ant-form-item .ant-form-item-control-input-content:focus-visible {
  outline: none;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0px;
}

.ant-form-large .ant-form-item .ant-form-item-label>label {
  height: 30px;
}

.ant-form-item {
  padding-bottom: 5px;
}


/********************************************************
 * input : Selector
 ********************************************************/
 .ant-select-single.ant-select-lg:focus-visible {
  outline: none;
 }

 .ant-select-selector:focus-visible {
  outline: none;
 }

 .ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-search {
  outline: none;
 }

 .ant-select-selection-item {
  outline: none;
 }

 .ant-select .ant-select-lg .ant-select-in-form-item .ant-select-status-success .ant-select-single .ant-select-show-arrow .ant-select-show-search {
  outline: none;
 }

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  box-shadow: 0 0 0 1px #1589FF;
}


/********************************************************
 * Button
 ********************************************************/
.ant-btn-default:not(:disabled):hover {
  box-shadow: 0 0 0 1px #1589FF;
}